import React from "react";

export const GetModule = props => {
	let schoolName = "สำนักการศึกษา";
	if (localStorage.getItem("USER_TYPE") === "1") {
		schoolName = localStorage.getItem("USER_ORGANIZATION_NAME");
	} else if (
		localStorage.getItem("USER_TYPE") === "2" ||
		localStorage.getItem("USER_TYPE") === "3"
	) {
		schoolName = "โรงเรียน" + localStorage.getItem("USER_SCHOOL_NAME");
	} else {
		schoolName = "สำนักการศึกษา";
	}

	return (
		<>
			<p
				className="d-none d-sm-block my-0 ml-1 align-self-center"
				style={{
					border: "1px solid #267659",
					boxSizing: "border-box",
					borderRadius: "6px",
					padding: "8px 12px",
					color: "#267659"
				}}
			>
				{schoolName}
			</p>
		</>
	);
};
