import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import { PopupLoading } from "./components/custom/popuploading/PopupLoading";
import "./index.scss";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<PopupLoading />}>
			<Layout>
				<IntlProviderWrapper>
					<LazyApp />
				</IntlProviderWrapper>
			</Layout>
		</Suspense>
	</Provider>,
	document.getElementById("root")
);
serviceWorker.unregister();
