import React from "react"
import * as Icon from "react-feather"

const navigationComponent = [
  {
    id: "settingrole",
    title: "จัดการสิทธิ์การใช้งานระบบ",
    type: "collapse",
    icon: <Icon.List size={20} />,
    badge: "primary",
    permissions: ["admin", "editor"],
    children: [
      {
        id: "settingprivilege",
        title: "ตั้งค่ากลุ่มสิทธิ์",
        type: "item",
        navLink: "/settingprivilege",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "settinguser",
        title: "ตั้งค่าผู้ใช้งาน",
        type: "item",
        navLink: "/settinguser",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
    ],
  },
  {
    id: "Monitoring",
    title: "Monitoring",
    type: "collapse",
    icon: <Icon.List size={20} />,
    badge: "primary",
    permissions: ["admin", "editor"],
    children: [
      {
        id: "session",
        title: "Session",
        type: "item",
        navLink: "/sessionlist",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "AccessLogs",
        title: "Access Logs",
        type: "item",
        navLink: "/accesslogs",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "ActivityLogs",
        title: "Activity Logs",
        type: "item",
        navLink: "/activitylogs",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
    ],
  },
  {
    id: "ComponentList",
    title: "Components List",
    type: "collapse",
    icon: <Icon.List size={20} />,
    badge: "primary",
    permissions: ["admin", "editor"],
    children: [
      {
        id: "button",
        title: "Buttons",
        type: "item",
        navLink: "/button",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "breadcrumb",
        title: "Breadcrumbs",
        type: "item",
        navLink: "/breadcrumb",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "collapse",
        title: "Collapses",
        type: "item",
        navLink: "/collapse",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "dropdown",
        title: "Dropdowns",
        type: "item",
        navLink: "/dropdown",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "modal",
        title: "Modals",
        type: "item",
        navLink: "/modal",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "paginationComponents",
        title: "Paginations",
        type: "item",
        navLink: "/PaginationComponents",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "progress",
        title: "Progress",
        type: "item",
        navLink: "/progress",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "spinners",
        title: "Spinners",
        type: "item",
        navLink: "/spinners",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "tableComponents",
        title: "Tables",
        type: "item",
        navLink: "/TableComponents",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "tableAddMultiRow",
        title: "Add Multi Row",
        type: "item",
        navLink: "/tableaddmultirow",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "AddMultiRowValidatePage",
        title: "Add Multi Row Validate",
        type: "item",
        navLink: "/addmultirowvalidate",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "formValidatePage",
        title: "Form Validate",
        type: "item",
        navLink: "/formvalidate",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "datatable",
        title: "DataTable",
        type: "item",
        navLink: "/datatable",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "textbox",
        title: "Textbox",
        type: "item",
        navLink: "/textbox",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "autocomplete",
        title: "Autocomplete",
        type: "item",
        navLink: "/autocomplete",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "textarea",
        title: "TextArea",
        type: "item",
        navLink: "/textarea",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "datepicker",
        title: "Datepicker",
        type: "item",
        navLink: "/datepicker",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "timepicker",
        title: "Timepicker",
        type: "item",
        navLink: "/timepicker",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
      },
      {
        id: "select",
        title: "Select",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/select",
      },
      {
        id: "Checkbox",
        title: "Checkbox",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/Checkbox",
      },
      {
        id: "Radio",
        title: "Radio",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/Radio",
      },
      {
        id: "Notifications",
        title: "Notifications",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/Notifications",
      },
      {
        id: "fileUpload",
        title: "File Upload",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/fileUpload",
      },
      {
        id: "uploadImage",
        title: "Upload Image",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadimage",
      },
      {
        id: "ImportFiles",
        title: "Import Files",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/importfiles",
      },
      {
        id: "ListGroup",
        title: "List Group",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/listgroup",
      },
      {
        id: "TabComponent",
        title: "Tab Component",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/tabcomponent",
      },
      {
        id: "Tooltip",
        title: "Tooltip",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/tooltip",
      },
      {
        id: "Popovers",
        title: "Popovers",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/popover",
      },
      {
        id: "Badges",
        title: "Badges",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/badges",
      },
      {
        id: "Icons",
        title: "Icons",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/feathericons",
      },
      {
        id: "Export",
        title: "Export",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/export",
      },
      {
        id: "DragDrop",
        title: "Drag & Drop",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/draganddrop",
      },
      {
        id: "Permission",
        title: "Permission",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/permission",
      },
      {
        id: "Label",
        title: "Label",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/label",
      },
      {
        id: "PopupLoading",
        title: "Popup Loading",
        type: "item",

        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/popuploading",
      },
      {
        id: "InputAddress",
        title: "Inputaddress",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/inputaddress",
      },
      {
        id: "SelectEducationLevel",
        title: "SelectEducationLevel",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/select_education_level",
      },
      {
        id: "UploadFile",
        title: "UploadFile",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadfile",
      },
    ],
  },
]

export default navigationComponent
