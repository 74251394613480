import { gql } from "@apollo/client";

export const GET_SHOW_MENU = gql`
  query showMenu($input: String!){
    showMenu(menuCode: $input){
      menuCode
      menuNameTH
      menuNameEN
      menuParentCode
      menuPath
      sort
      isActive
      systemCode
      icon
      level1 :childrenMenus {
        menuCode
        menuNameTH
        menuPath
        icon
        level2 :childrenMenus {
          menuCode
          menuNameTH
          menuPath
          icon
        }
      }
    }
  }
`;
export const GET_MODULE = gql`
  query {
    getByParentMenu(systemCode:"07", menuParentCode:"0000") {
      menuCode
      menuNameTH
      menuNameEN
      menuParentCode
      systemCode
      menuPath
      sort
      isActive
      icon
    }
  }
`;

export const GET_BY_PARENT_MENU = gql`
  query getByParentMenu($systemCode: String!, $menuParentCode: String!){
    getByParentMenu(systemCode: $systemCode, menuParentCode: $menuParentCode) {
      menuCode
      menuNameTH
      menuNameEN
      menuParentCode
      systemCode
      menuPath
      sort
      isActive
      icon
    }
  }
`;

export const GET_BY_PATH_MENU = gql`
  query getByPathMenu($input: String!){
    getByPathMenu(menuPath: $input){
      menuCode
      menuNameTH
      menuNameEN
      menuParentCode
      menuPath
      systemCode
      icon
      parentMenuLevel1: parentMenu {
        menuCode
        menuNameTH
        menuNameEN
        menuParentCode
        parentMenuLevel2: parentMenu {
          menuCode
          menuNameTH
          menuNameEN
          menuParentCode
        }
      }
    }
  }
`;
